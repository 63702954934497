import  { Menu} from 'lucide-react'
import { Link } from 'react-scroll';
import brandLogo from '../assets/icons/A.png'
import menu from '../assets/icons/menu.png'
function Navbar(): JSX.Element{
    return(
        <div className="navigation">
            <nav className="navbar navbar-expand-lg fixed-top bg-body navcontainer">
                <div className="container">
                    <Link className='navbar-brand' spy={true} to='overview' activeClass='active' smooth={true} duration={500} offset={-80}>
                        <img src={brandLogo} className="ms-3" style={{width: "40px", height:"40px"}} alt="Portfolio logo" />
                    </Link>
                    <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                        <img src={menu} alt="" style={{width:"40px", height:"40px"}}/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarToggler">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
                            <li className="nav-item">
                                <Link 
                                className='nav-link' 
                                to='about' 
                                activeClass='active' 
                                smooth={true} 
                                duration={500} 
                                offset={-130}
                                spy={true}
                                >
                                About
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link 
                                className='nav-link' 
                                to='projects' 
                                activeClass='active' 
                                smooth={true} 
                                duration={500} 
                                offset={-80}
                                spy={true}
                                >
                                    Projects
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link 
                                className='nav-link' 
                                to='journey'  
                                activeClass='active' 
                                smooth={true} 
                                duration={500} 
                                offset={-120}
                                spy={true}
                                >
                                    Journey
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link 
                                className='nav-link' 
                                to='contact'  
                                activeClass='active' 
                                smooth={true} 
                                duration={500} 
                                offset={-170}
                                spy={true}
                                >
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>            
        </div>
        
    )
}
export default Navbar;