import { Element } from "react-scroll";
import personalImage from '../assets/images/abubakrThree.png'
import underline from '../assets/icons/double-underline-blue.svg'
import { Github, Linkedin } from "lucide-react";
function Overview(): JSX.Element{
    return(
        <div className="overview p-5" data-aos="fade-in" style={{marginTop:"60px"}}>
            <Element name="overview">
                <div className="row">
                    <div className="col-lg-6">
                        <img src={personalImage} className="portfolio-image mx-auto d-block" alt="Picture of Abubak'r" />
                    </div>
                    <div className="col-lg-6 rightcolOverview">
                        <p className="text-center pt-5 fw-bolder display-5">
                            Full Stack Developer
                            <img src={underline} alt="double underline" className="pb-2 imageUnderline"/>
                        </p>
                        <p className="text-center tagline">
                        👋🏾 Hey there! I’m Abubak’r Mufutau-Oseni – a Full Stack Developer living in the United Kingdom.
                        </p>
                        <div className="social-links">
                            <a
                            href="https://www.linkedin.com/in/abubakrmo/"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                                <Linkedin className="linkedin-social"/>
                            </a>
                            <a
                            href="https://github.com/abubakrmo"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                                <Github className="github-social"/>
                            </a>
                        </div>
                    </div>
        
                </div>


            </Element>
        </div>
    )
}
export default Overview;