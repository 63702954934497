import { BriefcaseBusiness, GraduationCap, MoveRight } from "lucide-react";
import { Element } from "react-scroll";
function Journey(): JSX.Element{
    return(
        <div className="my-journey px-5 pb-5 pt-3" data-aos="fade-in">
            <Element name="journey">
            <h2 className="text-center fw-lighter">My Journey So Far</h2>
            <div className="timeline mt-4">
                <div className="timeline-container left">
                    <div className="content">
                        <h3>2023 - 2024</h3>
                        <p>MSc Advanced Software Engineering 🏫</p>
                        <p className="fw-lighter">University of Strathclyde.</p>
                    </div>
                </div>
                <div className="timeline-container right">
                    <div className="content">
                        <h3>2021 - 2022</h3>
                        <p>Junior Software Developer 👨🏿‍💻</p>
                        <p className="fw-lighter">Crownbirth Limited.</p>
                    </div>
                </div>
                <div className="timeline-container left">
                    <div className="content">
                        <h3>2018 - 2022</h3>
                        <p>BSc Computer Science 🏫</p>
                        <p className="fw-lighter">Adeleke University.</p>
                    </div>
                </div>
                <div className="timeline-container right">
                    <div className="content">
                    <h3>2018 - 2018</h3>
                    <p>Computer Science Intern 👨🏿‍💻</p>
                    <p className="fw-lighter">Zenith Computer World.</p>
                    </div>
                </div>
            </div>
            <h5 className="text-center mt-4">
                <a 
                href="https://drive.google.com/file/d/10rk75u4Bi5zsSCHsxPLMIfcFt2pdNt4c/view?usp=sharing" 
                target="_blank" 
                className="view-resume">
                    View Resume&nbsp;
                </a><MoveRight/>
            </h5>
            </Element>
        </div>
    )
}
export default Journey;