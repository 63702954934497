import { Element } from "react-scroll";
import iconPlane from '../assets/icons/icon-plane.png'
import React, {useState} from 'react';
import emailjs from '@emailjs/browser'
import {ToastContainer, toast, Bounce} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Send } from "lucide-react";
function ContactMe(): JSX.Element{
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
      });
      const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
      };
    
      const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        await emailjs
          .send(
            "service_00fxu6g",
            "template_an8l5ht",
            {
              from_name: formData.name,
              reply_to: formData.email,
              message: formData.message,
            },
            "mXoVWTT7vEKnlJ5tf"
          )
          .then(
            (response) => {
              toast.success("Message sent successfully!", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
              setFormData({ name: "", email: "", message: "" }); // Reset the form
            },
            (err) => {
              toast.error("Failed to send message. Please try again later.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
            }
          );
      };
    return(
        <div className="container-fluid">
              <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              transition={Bounce}
              /> 
            <div className="contact-me p-5" data-aos="fade-in">
              
              <Element name="contact">  
              <h2 className="text-center fw-lighter">
                  <img src={iconPlane} alt="" style={{width: "80px", height: "80px"}}/>
                  Contact
              </h2>
              <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                      Name
                  </label>
                  <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="John 'The Legend' Doe"
                      value={formData.name}
                      onChange={handleChange}
                      required
                  />
                  </div>
                  <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                      Email Address
                  </label>
                  <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="youremail@awesome.com"
                      value={formData.email}
                      onChange={handleChange}
                      required
                  />
                  </div>
                  <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                      Message
                  </label>
                  <textarea
                      className="form-control"
                      id="message"
                      placeholder="Enter your message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={10}
                      required
                  ></textarea>
                  </div>
                  <button type="submit" className="btn btn-outline-dark">
                      Send It My Way <Send/>
                  </button>
              </form>

              </Element>         
            </div>
        </div>
    )
}
export default ContactMe;