import Navbar from '../components/Navbar'
import AboutMe from '../components/AboutMe';
import Overview from '../components/Overview';
import Footer from '../components/Footer';
import ContactMe from '../components/ContactMe';
import Projects from '../components/Projects';
import Journey from '../components/Journey';


function Homepage(): JSX.Element{
    return(
        <div className="home" id="home">
            <Navbar/>
            <Overview/>
            <AboutMe/>
            <Projects/>
            <Journey/>
            <ContactMe/>
            <Footer/>
        </div>
    )
}
export default Homepage;