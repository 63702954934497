import { MoveRight } from "lucide-react";

interface ProjectCardPropsTwo{
    textHeader: string
    text: string
    link: string
    imagelink: string
    alt: string
    cardId: string
}
function ProjectCardTwo({imagelink, text, link, textHeader, alt, cardId}: ProjectCardPropsTwo): JSX.Element{
    return(
        <div>
            <a href={link} target="_blank" className="project-link">
                <div className="card my-3 project-card" id={cardId}>
                    <div className="card-body">
                        <h1 className="card-title fw-bold text-center mt-5">{textHeader}</h1>
                        <p className="card-text mt-4 text-center">{text}</p>
                        <button className="btn btn-dark mx-auto d-block mt-5 py-3 px-5 viewproject-button fw-bold">
                            View Project&nbsp;<MoveRight/>
                        </button>
                    </div>
                    <img src={imagelink} className="card-img-bottom" alt={alt}/>
                </div>
            </a>

        </div>
    )
}
export default ProjectCardTwo;