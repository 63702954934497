import { UserRound } from "lucide-react";
import { Element } from "react-scroll";
import ContactMe from "./ContactMe";
import Footer from "./Footer";
import Journey from "./Journey";
import Projects from "./Projects";
function AboutMe(): JSX.Element{
    return(
        <div className="about-me container-fluid mt-5 pt-5" data-aos="fade-in">
            <Element name="about">
                {/* <p className="text-center"><UserRound size={38}/></p> */}
                <h2 className="text-center fw-lighter">Who I Am & What I Use</h2>
                <div className="row pt-3 px-3">
                    <div className="col-lg-6 about-text">
                        <p>
                            I’m a Full Stack Developer and Software Engineer with over 
                            <span> 2 years</span> of professional experience.
                        </p>
                        <p>
                            My tech journey began at the age of 15, when I wrote my first line
                            of Java code to create a simple calculator 
                          . 
                        </p>
                        <p>
                        That tiny spark grew into a full-blown passion, 
                            leading me to earn a BSc in Computer Science and an 
                            MSc in Advanced Software Engineering
                            from the University of Strathclyde.
                        </p>
                        <p>
                            Today, I’m all about using code to make a positive difference in people’s lives. 
                        </p>
                    </div>
                    <div className="col-lg-4 ms-3 skillcol">
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <img alt="JavaScript, Typescript Icon" className="skillIcon" src="https://skillicons.dev/icons?i=js,ts" />
                            </li>
                            <li className="list-inline-item">
                                <img alt="Reactjs, Nodejs Icon" className="skillIcon"  src="https://skillicons.dev/icons?i=react,nodejs" />
                            </li>
                            <li className="list-inline-item">
                                <img alt="Expressjs, Git Icon" className="skillIcon"  src="https://skillicons.dev/icons?i=express,git" />
                            </li>
                            <li className="list-inline-item">
                                <img alt="Jest, Vitest Icon" className="skillIcon"  src="https://skillicons.dev/icons?i=jest,vitest" />
                            </li>
                            <li className="list-inline-item">
                                <img alt="Java, Jenkins Icon" className="skillIcon"  src="https://skillicons.dev/icons?i=java,jenkins" />
                            </li>
                            <li className="list-inline-item">
                                <img alt="Firebase, MySQL Icon" className="skillIcon"  src="https://skillicons.dev/icons?i=aws,mysql" />
                            </li>
                            <li className="list-inline-item">
                                <img alt="Bootstrap Icon" className="skillIcon"  src="https://skillicons.dev/icons?i=firebase,bootstrap" />
                            </li>
                        </ul>
                    </div>
                </div>
            </Element>


        </div> 
    )
}
export default AboutMe;