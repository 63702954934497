import { Element } from "react-scroll";
import orangeArrow from '../assets/icons/orange-arrow.svg'
import physicalActivity from '../assets/images/carefit.png'
import funcLive from "../assets/images/summariser.png"
import avatarMan from '../assets/images/muslimman.png'
import imageForFigma from "../assets/images/physicalactivity.png"
import ProjectCardTwo from "./ProjectCardTwo";
function Projects(): JSX.Element{
    return(
        <div className="my-projects container-fluid py-4" data-aos="fade-in">
            <Element name="projects">
            <h2 className="pb-2 ms-5 fw-lighter text-center">
                Projects
                <img src={orangeArrow} alt="Orange Arrow" style={{width: "80px", height: "80px"}}/>
            </h2>
            <ProjectCardTwo 
            imagelink={funcLive}
            alt="Screenshot of Function"
            textHeader="AI-Driven Response Generator"
            text="Developed using Node.js with Express.js, this function leverages 
            OpenAI's ChatGPT-4o model to summarise user prompts and suggest responses.
            "
            link="https://www.func.live/functions/summariseAndSuggestResponse"
            cardId="chatgptProject"
            />

            <ProjectCardTwo 
            imagelink={avatarMan}
            alt="An Illustration of a muslim man"
            textHeader="Dynamic Mosque Prayer Times"
            text="Developed using React.js with TypeScript, and deployed using AWS Amplify. This
            app fetches prayer times from the Aladhan API.
            "
            link="https://master.dhkv6e7l6iacn.amplifyapp.com/"
            cardId="masjidProject"
            />

            <ProjectCardTwo
            imagelink={physicalActivity}
            alt="Screenshot of sensor"
            textHeader="Motion Detection Sensor"
            text="Developed using TypeScript and deployed using cyberduck, the application leveraged 
            JavaScript's DeviceOrientationEvent
            properties to track movement.
            "
            link="https://devweb2022.cis.strath.ac.uk/~ywb22232/index.html"
            cardId="motionProject"
            />
            <ProjectCardTwo
            imagelink={imageForFigma}
            alt="Exercise Illustration"
            textHeader="Reducing Sedentary Behaviour"
            text="High-fidelity prototype designed using Figma to encourage older adults to engage in more physical activity.
            "
            link="https://www.figma.com/proto/KWOsjdhtIh2HTx7VK3sAiq/RSB?node-id=6-9&node-type=canvas&t=FDuwtbYArb0iMdNo-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=6%3A4"
            cardId="figmaProject"
            />
            </Element>
        </div>
    )
}
export default Projects;


// The application was deployed on Heroku and made accessible through a POST request to func.live. 

            // this web application dynamically fetches prayer times, the current day, 
            // and the corresponding Hijri date from the Aladhan API. 

            // : alpha, beta, and gamma,
            // The site was hosted using 
            // Cyberduck for deployment and it's best suited for mobile devices.

            // Key features include an educational section, instructional videos, activity tracking, 
            // mood journaling, and community challenges.
