import './styles/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Homepage from './page/Homepage';
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: 'ease-in-out',
      once: true, 
    });
  }, []);
  return (
    <div className="App">
      <Homepage/>
    </div>
  );
}

export default App;
